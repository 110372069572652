.wrapper {
    max-width: 1200px;
    margin: 30px 80px;
    display: block;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.container {
    display: grid;
    grid-gap: 30px;
    margin: 0 auto;
    grid-template-columns: 1fr 2fr 2fr;
    grid-template-areas: "name about contact" "sidebar content content" "sidebar content content" "sidebar content content" "footer footer footer";
}

.panel {
    flex: 1;
}

.name a {
    text-decoration: none;
    padding: 0;
    color: $dark-blue;
    background: none;
}

.name, .about, .social {
    border-bottom: 1px solid $yellow;
    padding: 40px 0;
}

.social {
    a:hover {
        border: none;
        i {
            color: $yellow;
        }

    }
    li {
        line-height: 1.4;
    }
}

.about {
    p {
        line-height: 1.55;
    }
    a {
        transition: none;
        &:hover {
            color: $dark-blue;
            background: none;
            padding: 0;
            border-bottom: 1px solid $yellow;
        }
    }
}

.sidebar {
    grid-row: 2 / 5;
    ul li {
        @include font-size(17px, 1.7rem);
    }
}

.work {
    ul li {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid $yellow;
        margin: 5px 5px 5px 0;
    }
    ul li:last-child {
        background: $yellow;
        &:hover {
            background: $dark-blue;
            border: 1px solid $dark-blue;
        }
        a {
            color: white;
            border: none;
        }
    }
}

.footer {
    padding: 20px 0;
    border-top: 1px solid $yellow;
    flex: 0 1 100%;
    grid-column: 1 / -1;
    p {
        @include font-size(14px, 1.4rem);
    }
}

@supports (display: grid) {
    .container > * {
        margin: 0;
    }
}

.work {
    flex: 0 1 100%;
    grid-column: 2 / -1;
}

@media (max-width: 768px) {
    .wrapper {
        margin: 40px auto;
        max-width: 90%;
    }
    .portfolio img {
        display: block;
        margin: 0 auto;
    }
    .container {
        grid-template-columns: 100%;
        grid-template-areas: "name" "about" "contact" "sidebar" "content" "content" "content" "content" "footer";
    }
    .work {
        flex: 1;
        grid-column: 1;
    }
    .name, .about, .social {
        padding: 0 0 15px 0;
    }
    .sidebar {
        grid-row: auto;
    }
    .name {
        order: 1;
    }
    .about {
        padding: 40px 0 0 0;
        order: 5;
        border-top: 1px solid $yellow;
        border-bottom: none;
    }
    .social {
        order: 2;
        border: none;
        ul li {
            display: inline;
            padding: 0 15px 0 0;
        }
    }
    .sidebar {
        order: 3;
    }
    .content {
        order: 4;
    }
    .footer {
        order: 6;
    }
}

@media (max-width: 400px) {
    .social {
        ul li {
            padding: 0 8px 0 0;
            @include font-size(14px, 1.4rem);
        }
    }
}