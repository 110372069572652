*,
*:before,
*:after {
    box-sizing: border-box;
}

html, body {
    font-size: 62.5%;
    height: 100%;
    box-sizing: border-box;
}

body {
    background-color: $light-gray;
    font-family: $font-ss;
    @include font-size(16px, 1.6rem);
    color: $gray;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-appearance: none;
    width: 100%;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
}

img, embed, object, video {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
    color: $dark-blue;
}

h1 {
    @include font-size(40px, 4rem);
    text-transform: uppercase;
    font-weight: 700;
}

h2 {
    @include font-size(26px, 2.6rem);
    margin-bottom: 40px;
    font-weight: 700;
}

h3 {
    @include font-size(30px, 3rem);
    margin-bottom: 30px;
}

p,
li,
table td,
table th,
blockquote {
    @include font-size(16px, 1.6rem);
    margin: 0 0 15px 0;
}

b, strong {
    font-weight: 700;
}

* + ul > li:first-child {
    margin-top: 0;
}

ul, ol {
    margin: 0 0 15px 0;
    padding: 0;
}

li {
    background: none;
    display: block;
    list-style: none;
    position: relative;
}

ul > li:before {
    display: block;
    left: 1px;
    position: absolute;
    top: 0;
}

a {
    color: $dark-blue;
    text-decoration: none;
    &:hover, &:focus {
        background: none;
        padding: 0;
        border-bottom: 1px solid $yellow;
    }
}

a:focus {
    outline: 3px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.name a:hover, .portfolio a:hover {
    border: none;
}

.go-transit {
    img {
        box-shadow: none;
    }
    img.shadow {
        box-shadow: 10px 10px 41px -12px rgba(112, 112, 112, 0.5);
        margin: 10px 0;
    }
}

img {
    box-shadow: 10px 10px 41px -12px rgba(112, 112, 112, 0.5);
    margin: 10px 0;
}

.portfolio {
    a > img {
        box-shadow: 10px 10px 41px -12px rgba(112, 112, 112, 1);
        &:hover {
            box-shadow: 10px 10px 41px -12px rgba(36, 53, 56, 1);
        }
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        @include font-size(35px, 3.5rem);
    }
    h2 {
        @include font-size(25px, 2.5rem);
    }
    h3 {
        @include font-size(22px, 2.2rem);
    }
    h4 {
        @include font-size(18px, 1.8rem);
    }
}