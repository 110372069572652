
$light-gray: #f6f6f6;
$gray: rgba(22,25,37,0.7);
$blue: #8EDCE6;
$dark-blue: rgba(36,53,56,1);
$yellow: #cfb53b;

// Typography
$font: 'Roboto Slab', serif;
$font-ss: 'Roboto', sans-serif;
